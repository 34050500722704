import React, { useState } from "react";
import Modal from "react-modal";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { history } from "./../../routers/AppRouter";
import qs from "qs";
import Loading from "./../ui/Loading";
import moment from "moment";
import { GET_CORPORATE_DISCOUNTS } from "./../../gql/corporateDiscount/query";
import { DELETE_CORPORATE_DISCOUNT, CREATE_CORPORATE_DISCOUNT } from "./../../gql/corporateDiscount/mutation";
import Th from "./../ui/TableHead";
import Td from "./../ui/TableData";
import ErrorContainer from "./../ui/ErrorContainer";
import Delete from "./../ui/Delete";
import { FcRefresh, FcPlus } from "react-icons/fc";
import "moment/locale/tr";
moment.locale("tr");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "400px",
    textAlign: "center",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const recordsPerPage = 500;

export default () => {
  var search = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const [deleteDiscount] = useMutation(DELETE_CORPORATE_DISCOUNT);
  const [createDiscount] = useMutation(CREATE_CORPORATE_DISCOUNT);
  const [first, setFirst] = useState(recordsPerPage);
  const [page, setPage] = useState(search.page ? search.page : 1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showApi, setShowApi] = useState(false);

  const skip = (page - 1) * recordsPerPage;

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_CORPORATE_DISCOUNTS, {
    variables: { skip, first },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (loading || networkStatus === 4) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className="p-5 text-center bg-red-100 border border-red-600">
        <p>Veri yüklenirken bir hata oluştu.</p>
        <p className="text-red-800">{error.message}</p>
      </div>
    );
  }

  const totalPages = Math.ceil(data.discountCodes.totalDiscounts / recordsPerPage);

  const handleDelete = async (id) => {
    try {
      const result = await deleteDiscount({
        variables: { id },
      });
      if (result) {
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreate = async () => {
    setErrorMessage("");
    if (!phoneNumber) {
      return setErrorMessage("Telefon numarası girilmelidir");
    }
    try {
      const result = await createDiscount({
        variables: { phoneNumber },
      });
      if (result) {
        setPhoneNumber("");
        setOpen(false);
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderData = (data) => {
    if (data.length === 0) {
      return <div>Veri Yok</div>;
    }
    console.log(data);

    return data.discountCodes.discountCodes.map((item, i) => {
      return (
        <tr key={item.id}>
          <Td custom="text-center w-1/12">{skip + i + 1}</Td>
          <Td custom=" whitespace-no-wrap">{item.phoneNumber}</Td>
          <Td custom=" whitespace-no-wrap">{item.code}</Td>
          <Td custom=" whitespace-no-wrap">
            {item.amount} {item.discountType === "PERCENTAGE" ? "%" : "birim"}
          </Td>
          <Td custom=" whitespace-no-wrap">{item.company}</Td>
          <Td custom=" whitespace-no-wrap">{moment(item.createdAt).format("DD MMM YYYY HH:mm")}</Td>
          <Td custom=" whitespace-no-wrap">
            {item.updatedAt === item.createdAt ? "-" : moment(item.updatedAt).format("DD MMM YYYY HH:mm")}
          </Td>
          <Td custom={`${item.isUsed && "bg-green-300"} whitespace-no-wrap`}>
            {item.isUsed ? item.orderId : "Kullanılmadı"}
          </Td>
        </tr>
      );
    });
  };
  return (
    <div className="mt-4">
      <div className="flex justify-between mb-3">
        <button>
          {parseInt(page) * first} / {data.discountCodes.totalDiscounts} kayıt gösteriliyor.
        </button>
        <div>
          <button
            className="inline-flex items-center px-4 py-2 mr-3 font-bold text-gray-800 bg-white rounded hover:bg-gray-200"
            onClick={() => setShowApi(!showApi)}
          >
            API KEY GÖSTER
          </button>

          <button
            onClick={() => refetch()}
            className="inline-flex items-center px-4 py-2 mr-3 font-bold text-gray-800 bg-white rounded hover:bg-gray-200"
          >
            <FcRefresh />
          </button>

          <button
            onClick={openModal}
            className="inline-flex items-center px-4 py-2 font-bold text-gray-800 bg-white rounded hover:bg-gray-200"
          >
            <FcPlus />
          </button>
        </div>
      </div>

      <Modal ariaHideApp={false} isOpen={open} onRequestClose={closeModal} style={customStyles} contentLabel="Delete">
        <h2 className="font-bold">Yeni indirim yaratıyorsunuz. Onaylıyor musunuz?</h2>
        {errorMessage && <ErrorContainer title={errorMessage} />}
        <div>
          <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">Telefon</label>
          <input
            className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white"
            type="text"
            placeholder="Telefon"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value.trim())}
          />
        </div>

        <div className="flex justify-center mt-6">
          <button
            className="px-2 py-1 mr-4 text-xs text-gray-100 bg-red-600 rounded hover:bg-red-400"
            onClick={() => handleCreate()}
          >
            Onaylıyorum
          </button>
          <button
            className="px-2 py-1 mr-4 text-xs text-gray-100 bg-blue-600 rounded hover:bg-blue-400"
            onClick={() => {
              setPhoneNumber("");
              setErrorMessage("");
              return closeModal();
            }}
          >
            Vazgeç
          </button>
        </div>
      </Modal>
      {showApi && (
        <div className="p-4 mb-4 text-sm text-center break-all bg-gray-300 shadow-md">
          <strong>API KEY: </strong>
          <br />
          {localStorage.getItem("token")}

          <br />
        </div>
      )}
      <table className="w-full table-auto">
        <thead>
          <tr>
            <Th>#</Th>
            <Th custom="text-left whitespace-no-wrap">Telefon</Th>
            <Th custom="text-left whitespace-no-wrap">Kod</Th>
            <Th custom="text-left whitespace-no-wrap">Miktar</Th>
            <Th custom="text-left whitespace-no-wrap">Firma</Th>
            <Th custom="text-left whitespace-no-wrap">Oluşturma Tarihi</Th>
            <Th custom="text-left whitespace-no-wrap">Kullanım Tarihi</Th>
            <Th custom="text-left whitespace-no-wrap">Kullanım</Th>
          </tr>
        </thead>
        <tbody>{renderData(data)}</tbody>
      </table>

      <div className="flex justify-center mt-4">
        <nav className="relative z-0 inline-flex -space-x-px shadow-sm" aria-label="Pagination">
          <button
            onClick={() => {
              setPage(1);
              refetch();
            }}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
          >
            İlk Sayfa
          </button>
          <button
            onClick={() => {
              setPage(page > 2 ? page - 1 : 1);
              refetch();
            }}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
          >
            Geri
          </button>
          <button className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50">
            {page} / {totalPages}
          </button>
          <button
            onClick={() => {
              setPage(page < totalPages ? page + 1 : totalPages);
              refetch();
            }}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
          >
            İleri
          </button>
          <button
            onClick={() => {
              setPage(totalPages);
              refetch();
            }}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
          >
            Son Sayfa
          </button>
        </nav>
      </div>
    </div>
  );
};
