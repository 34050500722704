import React from "react";
import { ReactSVG } from "react-svg";

const Header = (props) => {
  return (
    <div className="w-full bg-gray-300">
      <div className="container mx-auto">
        <div className="flex justify-betwwen items-center font-semibold text-white">
          <div style={{ width: "100px", margin: "auto" }}>
            <ReactSVG src={"img/kk_logo.svg"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
