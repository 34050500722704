import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { createBrowserHistory } from "history";
import client from "./../apollo/apollo";
import ScrollToTop from "./ScrollToTop";
import PrivateRoute from "./PrivateRoute";

import Dashboard from "./../components/Dashboard";

import CorporateDiscount from "../components/corporateDiscount/CorporateDiscount";
export const history = createBrowserHistory();

const AppRouter = () => {
  return (
    <Router history={history}>
      <ApolloProvider client={client}>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <PrivateRoute path="/corporateDiscounts" component={CorporateDiscount} />
          </Switch>
        </ScrollToTop>
      </ApolloProvider>
    </Router>
  );
};

export default AppRouter;
