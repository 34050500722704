import gql from "graphql-tag";

const GET_CORPORATE_DISCOUNTS = gql`
  query CorporateDiscounts($first: Int, $skip: Int) {
    discountCodes(first: $first, skip: $skip) {
      totalDiscounts
      discountCodes {
        id
        phoneNumber
        amount
        discountType
        code
        company
        orderId
        isUsed
        updatedAt
        createdAt
      }
    }
  }
`;

const GET_CORPORATE_DISCOUNT = gql`
  query CorporateDiscount($id: ID!) {
    discountCode(id: $id) {
      id
      phoneNumber
      amount
      discountType
      code
      company
      orderId
      isUsed
      updatedAt
      createdAt
    }
  }
`;

export { GET_CORPORATE_DISCOUNT, GET_CORPORATE_DISCOUNTS };
