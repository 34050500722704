import gql from "graphql-tag";

const CREATE_CORPORATE_DISCOUNT = gql`
  mutation CreateDiscountCode($phoneNumber: String) {
    createDiscountCode(data: { phoneNumber: $phoneNumber }) {
      id
    }
  }
`;

const UPDATE_CITY = gql`
  mutation UpdateCity($id: ID!, $title: String, $cityId: Int, $latitude: Float, $longitude: Float) {
    updateCity(id: $id, data: { title: $title, cityId: $cityId, latitude: $latitude, longitude: $longitude }) {
      id
      title
    }
  }
`;

const DELETE_CORPORATE_DISCOUNT = gql`
  mutation DeleteDiscountCode($id: ID!) {
    deleteDiscountCode(id: $id) {
      id
    }
  }
`;

export { CREATE_CORPORATE_DISCOUNT, UPDATE_CITY, DELETE_CORPORATE_DISCOUNT };
